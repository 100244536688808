<template>
  <Toast/>
  <Breadcrumb :model="items"/>
  <DataTable :value="data" class="p-mt-3" :auto-layout="true">
    <template #header>
      <div class="p-d-flex" style="text-align: left">
        <Button class="p-mr-2" @click="show=true" label="新增轮播图" icon="pi pi-plus"/>
      </div>
    </template>
    <Column field="picIndex" header="序号"></Column>
    <Column field="title" header="文章名称"></Column>
    <Column field="pic" header="图片">
      <template #body="a">
        <img style="width:150px;" class="product-image" :src="url+a.data.pic"/>
      </template>
    </Column>
    <Column header="操作">
      <template #body="a">
        <Button :class="{'p-disabled':a.data.picIndex==0}" @click="changeOrder(a.data.id,1)" icon="pi pi-arrow-up" class="p-button-rounded  p-mr-2 p-button-sm" />
        <Button :class="{'p-disabled':a.data.picIndex==data.length-1}" @click="changeOrder(a.data.id,2)" icon="pi pi-arrow-down" class="p-button-rounded p-mr-2 p-button-sm" />
        <Button @click="update(a.data)" class="p-mr-2 p-button-sm">编辑</Button>
        <Button @click="deleteOne(a.data)" class="p-button-sm p-button-danger p-mr-2" label="删除"/>
      </template>
    </Column>
  </DataTable>
  <Dialog style="width:50%" v-model:visible="show" @hide="clearForm" header="轮播图">
    <div>
      <div class="p-fluid p-mt-4">
        <label>关联招聘：</label>
        <Dropdown v-model="form.articleId"
                  :options="RecruitList" optionLabel="job" optionValue="id" placeholder="选择招聘"/>
      </div>
      <div class="p-fluid p-mt-4">
        <h6>图片尺寸比例：4:3，建议大小：400*300</h6>
        <FileUpload v-model="form.pic" name="file" :url="fileUploadUrl" chooseLabel="选择图片"
                    @upload="onUpload" @select="selectPic" auto="true" mode="basic" accept="image/*"/>
        <img class="p-mt-2" style="width: 100px" :src="picUrl">
      </div>
      <div  class="p-field p-grid p-jc-evenly p-mt-4">
          <Button @click="submit" icon="pi pi-fw pi-save" label="保存"></Button>
      </div>
    </div>
  </Dialog>
  <Dialog header="确认框" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
      <span>你确认删除{{delDate.title}}吗?</span>
    </div>
    <template #footer>
      <Button label="是" icon="pi pi-check" @click="del" />
      <Button label="否" icon="pi pi-times" @click="closedel" class="p-button-secondary"/>
    </template>
  </Dialog>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      delDate:{},
      displayConfirmation:false,
      type:null,
      RecruitList:[],
      columnId:null,
      columnIdList: [],
      belongList: [{belong: '网站', value: 1}, {belong: '公众号', value: 2}],
      url:this.$global_msg.imgurl,
      fileUploadUrl: this.$global_msg.frontendUrl + "/file/uploadOne",
      picUrl: null,
      items: [
        {label: '招聘管理', to: '/recruit/carouselChart'},
        {label: '轮播图管理', to: '/recruit/carouselChart'}
      ],
      show: false,
      List: [],
      data:[],
      form: {
        pic: null,
        articleId: 0,
        type: 2,
      }
    }
  },
  mounted() {
    this.init()
    this.getRecruit()
  },
  methods: {
    init() {
      let ths = this;
      this.$http.get('/Rotating/getAll', {params: {type: 2}}).then((res) => {
        console.log(res)
        ths.data = res.data
      })
    },
    addArticle(a){
      this.form.articleId=a.value
    },
    getRecruit(){
      this.$http.get('/companies/getNowRecruit' ).then((res) => {
        console.log(res.data)
        this.RecruitList = res.data
      })
    },
    deleteOne(data){
      this.delDate=data
      this.displayConfirmation=true
    },
    closedel(){
      this.delDate ={}
      this.displayConfirmation =false
    },
    del(){
      this.$http.post('/Rotating/del?id='+this.delDate.id).then(()=>{
        this.displayConfirmation=false
        this.$toast.add({severity: 'success', summary: '成功', life: 3000});
        this.init()
      })
    },
    update(data) {
      this.show = true
      this.form = data;
      this.picUrl = this.url + data.pic
      //this.form.title =data.title
    },
    submit() {
      let ths= this
      this.$http.post('/Rotating/addOrUpdatePic', this.form).then(() => {
        this.$toast.add({severity: 'info', summary: '成功', detail: '提交成功', life: 3000});
        ths.show=false
        ths.init()

      })
    },
    clearForm(){
      this.form.pic=null
      this.articleId=null
      this.type=1
      this.columnId=null
      this.picUrl=null
      this.type=null

    },
    selectPic(event) {
      this.picUrl = event.files[0].objectURL
    },
    onUpload(event) {
      console.log(event)
      this.form.pic = event.xhr.response
      if(event.xhr.response!='上传失败'){
        this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});
      }else {
        this.$toast.add({severity: 'error', summary: '失败', detail: '文件上传失败', life: 3000});
      }

    },
    changeOrder(id,order){
      this.$http.post('/Rotating/changeOrder/'+id+'/'+order).then(()=>{this.init()})
    }
  }
}
</script>

<style scoped>

</style>